import React, { Componen,Suspense,lazy } from "react";
import { Route, Switch, BrowserRouter as Router, Link } from "react-router-dom";
import { Redirect } from "react-router";
import layouts from "./routes";
import AuthHoc from "./hoc/AuthHoc";
import LoginHoc from "./hoc/LoginHoc";
/* Start Analytics Code */
import ReactGa from "react-ga";
import TagManager from "react-gtm-module";
import i18n from "./i18n";
import { isNgCountry } from "./utils/misc";
import platformParams from "./utils/platformParams";
const sysend = require('sysend');

  // import { Helmet } from "react-helmet";
  export const GA_TRACKING = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  setTimeout(() => {
    window.location.href='https://mtnarena.co.za';
  }, 5000);

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
  };
  ReactGa.initialize(GA_TRACKING);
  ReactGa.pageview(window.location.pathname + window.location.search);
  !isNgCountry() && TagManager.initialize(tagManagerArgs);

  let dataLayer = window.dataLayer || [];

  function gtag(){dataLayer.push(arguments);}
  gtag('config', 'AW-10837359278')
  
  /* End Analytics Code */
  const lang = i18n.language;
  const AppRouter = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
      <Layout {...props}>
        <Suspense fallback={
          <>
          <div
          className={
            "load-bar " +
            platformParams['baseColor']
          }
        >
          <div className="bar" />
          <div className="bar" />
        </div>
        <div className="loading"></div>
        </>
        } >
          <Component {...props} />
          </Suspense> 
      </Layout>
    )} />
  )

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    if (lng === "fr") {
      localStorage.setItem("language", 2);
    } else {
      localStorage.setItem("language", 1);
    }
  };

  let doTabCheck = (path, localeString) => {
    let pathName = window.location.pathname;
    let isGamePage = (pathName.indexOf('/challenge/') > -1 || pathName.indexOf('/free-game-detail/') > -1);
    if(isGamePage) {
        sysend.on('notification', function() {
            sysend.broadcast('multiple');
        });
        sysend.on('multiple', function() {
            // Check if either of the aboveTabUris are in the current path
            if(pathName.indexOf('/challenge/') > -1 || pathName.indexOf('/free-game-detail/') > -1) {
                // Update modal state for onload on dash
                window.location = `/${localeString}/dashboard?multipletabs=1`;
            }
        });
        sysend.broadcast('notification');
    }
  };

  let App = props => {
    if (lang != props.match.params.locale) {
      changeLanguage(props.match.params.locale);
      if (localStorage.getItem("language") == null) {
        localStorage.setItem("language", 1);
      }
    }

    let envTitle = platformParams['envTitle'];
    let favicon = platformParams['favicon'];

    // Ensure only one tab is open. If another one is found, navigate that tab to dashboard.
    doTabCheck(props.location.pathname, props.match.params.locale);

    return (
      <>

        <Switch>
          {layouts.map((layout, i) => {
            return layout.routes.map((route, i) => (
              <AppRouter
                key={i}
                path={props.match.url + route.path}
                // path={route.path}
                layout={layout.layout}
                exact={route.exact}
                component={props => {
                  let Auth = route.auth
                    ? AuthHoc(route.container)
                    : route.allowAfterLogin
                    ? route.container
                    : LoginHoc(route.container);
                  return (
                    // <layout.layout {...props}>
                      <Auth {...props} />
                    // </layout.layout>
                  );
                }}
              />
            ));
          })}
        </Switch>
      </>
    );
  };

  export default App;